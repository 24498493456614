import React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
const CustomSoftwareDevelopment = React.lazy(() =>
  import("../components/CustomSoftwareDevelopment")
)

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/custom-software-development/"
        />
      </Helmet>
      <React.Suspense fallback={<div />}>
        <CustomSoftwareDevelopment />
      </React.Suspense>
    </Layout>
  )
}
